<template>
  <div class="register-page">
    <!-- Hero Section -->
    <section class="hero is-primary is-bold">
      <div class="hero-body">
        <div class="container has-text-centered">
          <h1 class="title is-size-1-touch">Register</h1>
        </div>
      </div>
    </section>

    <!-- Registration Form Section -->
    <section class="section">
      <div class="container">
        <div class="columns is-centered">
          <div class="column is-half">
            <form @submit.prevent="register" class="box">
              <!-- Email Field -->
              <div class="field">
                <label class="label">Email</label>
                <div class="control has-icons-left">
                  <input
                    type="email"
                    placeholder="e.g. bobsmith@gmail.com"
                    class="input"
                    v-model="email"
                    required
                  >
                  <span class="icon is-small is-left">
                    <i class="fa fa-envelope"></i>
                  </span>
                </div>
              </div>

              <!-- Password Field -->
              <div class="field">
                <label class="label">Password</label>
                <div class="control has-icons-left">
                  <input
                    type="password"
                    placeholder="*******"
                    class="input"
                    v-model="password"
                    required
                  >
                  <span class="icon is-small is-left">
                    <i class="fa fa-lock"></i>
                  </span>
                </div>
              </div>

              <!-- Group Field -->
              <div class="field">
                <label class="label">Group</label>
                <div class="control has-icons-left">
                  <input
                    type="text"
                    placeholder="e.g. Polanie, Słowianie, etc..."
                    class="input"
                    v-model="groupName"
                  >
                  <span class="icon is-small is-left">
                    <i class="fa fa-layer-group"></i>
                  </span>
                </div>
              </div>

              <!-- Redirect to Login -->
              <div class="has-text-centered">
                <p class="is-size-7">
                  Already have an account?
                  <router-link to="/login" class="has-text-primary">Log In</router-link>
                </p>
              </div>

              <!-- Submit Button -->
              <div class="field">
                <button type="submit" class="button is-success is-fullwidth">
                  Register
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import firebase from "firebase";
import { useUserStore } from "../store";
import UserService from "../services/userService";

export default {
  name: "Register",
  setup() {
    const store = useUserStore();
    store.hydrateFromLocalStorage();
    return { store };
  },
  data() {
    return {
      email: "",
      password: "",
      groupName: ""
    };
  },
  methods: {
    async register() {
      firebase
        .auth()
        .createUserWithEmailAndPassword(this.email, this.password)
        .then(async () => {
          // Check if the user exists in the database
          const existingUser = await UserService.getByEmail(this.email);
          if (!existingUser) {
            await UserService.create({
              email: this.email,
              group: this.groupName,
              currentWithDues: false,
              isBoardMember: false
            });
          }
          this.store.login(this.email);
          this.$router.push("/");
        })
        .catch((err) => {
          this.$swal({
            icon: "error",
            title: "Oops...",
            text: err.message
          });
        });
    }
  }
};
</script>

<style lang="scss" scoped>
/* Hero Section */
.hero-body {
  background: linear-gradient(to right, #93291e, #ed213a);
  // margin-bottom: 40px;
}

/* Form Styling */
.box {
  border-radius: 8px;
  padding: 2rem;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
}

.field {
  margin-bottom: 1.5rem;
}

.input {
  padding: 0.75rem;
  border-radius: 5px;
}

.button.is-success {
  font-weight: bold;
  margin-top: 1rem;
}

/* Responsive Design */
@media screen and (max-width: 768px) {
  .hero-body {
    padding: 2rem 1.5rem;
  }

  .box {
    padding: 1.5rem;
  }

  .input {
    font-size: 0.9rem;
  }
}
</style>
